var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",staticClass:"form py-1",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-6",attrs:{"label":_vm.$t('admin-form.first-name'),"placeholder":_vm.$t('admin-form.first-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('admin-form.last-name'),"placeholder":_vm.$t('admin-form.last-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('admin-form.e-mail'),"placeholder":_vm.$t('admin-form.e-mail-placeholder'),"is-error":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"phone|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('admin-form.phone'),"placeholder":_vm.$t('admin-form.phone-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)}),_c('div',{staticClass:"mt-6 flex items-start"},[_c('span',{staticClass:"w-48 mr-4"},[_vm._v(" "+_vm._s(_vm.$t('admin-form.super-admin-access'))+" ")]),_c('switch-input',{staticClass:"mt-1",attrs:{"disabled":_vm.isLoading},on:{"input":function($event){_vm.restrictedAccess = !$event}},model:{value:(_vm.superAdminAccess),callback:function ($$v) {_vm.superAdminAccess=$$v},expression:"superAdminAccess"}})],1),_c('div',{staticClass:"mt-6 flex items-start"},[_c('span',{staticClass:"w-48 whitespace-pre-wrap mr-4"},[_vm._v(" "+_vm._s(_vm.$t('admin-form.restrict-clients'))+" ")]),_c('switch-input',{staticClass:"mt-1",attrs:{"disabled":_vm.isLoading},on:{"input":function($event){_vm.superAdminAccess = !$event}},model:{value:(_vm.restrictedAccess),callback:function ($$v) {_vm.restrictedAccess=$$v},expression:"restrictedAccess"}})],1),(_vm.restrictedAccess)?[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{staticClass:"mt-5",attrs:{"url":"organizations","placeholder":_vm.$t('admin-form.choose-client'),"error-message":errors[0],"option-value-name":"id","option-label-name":"name","required":"","multiple":"","autocomplete":""},model:{value:(_vm.relatedOrganizations),callback:function ($$v) {_vm.relatedOrganizations=$$v},expression:"relatedOrganizations"}})]}}],null,true)})]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }