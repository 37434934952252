<template>
  <div class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-4 md:px-12">
      <breadcrumbs
        :path="[
          { name: $t('add-admin-view.employees'), url: { name: 'Admins' } },
          { name: $t('add-admin-view.add-employee') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <admin-form
        id="adminForm"
        class="mb-64"
        :clear="submissionEndSuccessfully"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-center xs:justify-between flex-wrap">
        <div class="flex">
          <btn
            :is-loading="redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="adminForm"
            class="md:w-48 mr-3"
            @click="redirectAfterSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
          <btn
            :is-loading="!redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="adminForm"
            class="w-48 md:w-auto md:mx-3"
            theme="secondary"
            :compact="mobileView"
            @click="redirectAfterSubmission = false"
          >
            {{ $t('admin-form.save-and-add-another') }}
          </btn>
        </div>
        <btn theme="none" class="mt-2 xs:mt-0" @click="$router.go(-1)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import AdminForm from '@/views/Dashboard/Admins/Forms/AdminForm.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AddAdmin',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    AdminForm,
  },
  data() {
    return {
      redirectAfterSubmission: false,
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  computed: mapState('ui', { mobileView: (state) => state.mobileView.active }),
  methods: {
    ...mapActions('admin', ['addAdmin']),
    async handleSubmission(formData) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        await this.addAdmin(formData);
        if (this.redirectAfterSubmission) this.$router.push({ name: 'Admins' });

        this.$toast.success(this.$t('add-admin-view.added-successfully'));

        this.submissionEndSuccessfully = true;
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
