<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      :id="id"
      ref="form"
      class="form py-1"
      @submit.prevent="handleSubmit(pushFormData)"
      @reset.prevent="reset"
    >
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="firstName"
          :label="$t('admin-form.first-name')"
          :placeholder="$t('admin-form.first-name-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="mt-6"
        ></text-input>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="lastName"
          :label="$t('admin-form.last-name')"
          :placeholder="$t('admin-form.last-name-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="mt-5"
        ></text-input>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" rules="email|required">
        <text-input
          v-model="email"
          :label="$t('admin-form.e-mail')"
          :placeholder="$t('admin-form.e-mail-placeholder')"
          :is-error="errors[0]"
          :disabled="isLoading"
          required
          class="mt-5"
        ></text-input>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="phone"
        rules="phone|required"
      >
        <text-input
          v-model="phone"
          :label="$t('admin-form.phone')"
          :placeholder="$t('admin-form.phone-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="mt-5"
        ></text-input>
      </ValidationProvider>
      <div class="mt-6 flex items-start">
        <span class="w-48 mr-4">
          {{ $t('admin-form.super-admin-access') }}
        </span>
        <switch-input
          v-model="superAdminAccess"
          :disabled="isLoading"
          class="mt-1"
          @input="restrictedAccess = !$event"
        ></switch-input>
      </div>
      <div class="mt-6 flex items-start">
        <span class="w-48 whitespace-pre-wrap mr-4">
          {{ $t('admin-form.restrict-clients') }}
        </span>
        <switch-input
          v-model="restrictedAccess"
          :disabled="isLoading"
          class="mt-1"
          @input="superAdminAccess = !$event"
        ></switch-input>
      </div>
      <template v-if="restrictedAccess">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <select-input
            v-model="relatedOrganizations"
            url="organizations"
            :placeholder="$t('admin-form.choose-client')"
            :error-message="errors[0]"
            option-value-name="id"
            option-label-name="name"
            required
            multiple
            autocomplete
            class="mt-5"
          />
        </ValidationProvider>
      </template>
    </form>
  </ValidationObserver>
</template>

<script>
import { SwitchInput } from '@/components/Inputs';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { phoneRegex, emailRegex } from '@/util/form';
import { TextInput, SelectInput } from '@/components/Inputs';

export default {
  components: {
    SelectInput,
    TextInput,
    SwitchInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: null,
    },
    clear: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: null,
      restrictedAccess: true,
      superAdminAccess: false,
      relatedOrganizations: [],
    };
  },
  computed: {
    relatedOrganizationIds() {
      return this.relatedOrganizations.map((el) => el.id);
    },
  },
  watch: {
    restrictedAccess() {
      this.relatedOrganizations = [];
    },
    clear(val) {
      if (val) this.clearForm();
    },
    content(newContent) {
      if (newContent !== null) {
        const {
          firstName,
          lastName,
          email,
          phone,
          hasAccessToAllOrganizations,
          relatedOrganizations,
        } = newContent;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.superAdminAccess = hasAccessToAllOrganizations;
        this.restrictedAccess = !hasAccessToAllOrganizations;
        this.relatedOrganizations = relatedOrganizations;
      }
    },
  },
  created() {
    extend('email', {
      validate(value) {
        return value.match(emailRegex);
      },
      message: this.$t('errors.error-email'),
    });

    extend('phone', {
      validate(value) {
        return value.match(phoneRegex);
      },
      message: this.$t('errors.error-phone'),
    });

    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  methods: {
    pushFormData() {
      this.$emit('on-submit', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        hasAccessToAllOrganizations: this.superAdminAccess,
        relatedOrganizationIds: this.superAdminAccess
          ? []
          : this.relatedOrganizationIds,
      });
    },
    clearForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
      this.restrictedAccess = true;
      this.relatedOrganizations = [];
      this.superAdminAccess = false;

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
